<template>
<div class="guide animated" :class="{'bounceInLeft':guidePk}" id="guide">
  <div v-if="guidePk"></div>
  <div class="guideTitle">
  服务指南<i class="el-icon-caret-right"></i>
  </div>
  <div class="checkList">
    <div class="checkEver"  :class="{'checked':(checkPk=='integrated')}" @click="checkChange('integrated')">
      一体化安装
    </div>
    <div class="checkEver" :class="{'checked':(checkPk=='customized')}" @click="checkChange('customized')">
      个人定制版系统
    </div>
    <div class="checkEver" :class="{'checked':(checkPk=='saleTeam')}" @click="checkChange('saleTeam')">
      完善的售后团队
    </div>
  </div>
  <component :is="comp"></component>
</div>
</template>

<script>
import integrated from "./integrated";
import customized from "./customized";
import saleTeam from "./saleTeam";
export default {
  name: "guide",
  props:{
    guidePk:Boolean
  },
  components:{
    integrated,customized,saleTeam
  },
  data(){
    return{
      checkPk:"integrated",
      comp:"integrated"
    }
  },
  methods:{
    checkChange(option){
      this.checkPk=option;
      this.comp=option
    }

  }

}
</script>

<style lang="scss" scoped>
.guide{
  position: relative;
  width: 1920px;
  height: 600px;
  background: white;
}
.guideTitle{
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  color: #666666;
  i{
    margin-left: 10px;
    color: #666666;
  }
}
.checkList{
  margin: 0 auto;
  width: 1200px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding: 0 150px;
  box-sizing: border-box;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 70px;
}
.checkEver{
 min-width: 120px;
  height: 45px;
  border: 1px solid #666666;
  color: #666666;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: center;
  line-height: 45px;
  font-size: 18px;
  cursor: pointer;

}
.checked{
  background: #EEAA3D;
  color: white;
  border-style: none;
}
</style>
