<template>
<div class="questEver">
  <div class="questTitle" :class="{'checked':questPk}" @click="questChange">{{questTitle}}</div>
  <div class="questMain" v-show="questPk">{{questMain}}</div>
</div>
</template>

<script>
export default {
  name: "questEver",
  props:{
    questTitle:String,
    questMain:String
  },
  data(){
    return{
      questPk:false
    }
  },
  methods:{
    questChange(){
      this.questPk=!this.questPk;
    }
  }
}
</script>

<style lang="scss" scoped>
.questEver{
  width: 1200px;
  margin: 10px;
  cursor: pointer;
}
.questTitle{
  font-size: 16px;
  margin-bottom: 5px;
  color: #666666;
}
.questMain{
  position: relative;
  width: 1200px;
  background: white;
  padding: 9px;
  box-sizing: border-box;
  font-size: 12px;
  color: #666666;
}
.checked{
  color: #EEAA3D;
}
</style>
