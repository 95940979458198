<template>
<div class="afterSale" ref="afterSale" @scroll="testScroll">
  <gt-header checked="shfw"></gt-header>
<banner></banner>
  <guide :guide-pk="guidePk"></guide>

  <self-service @showChat="selfChat" :service-pk="servicePk" v-if="false"></self-service>
  <service-scope :scope-pk="scopePk" v-if="false"></service-scope>
  <gt-footer></gt-footer>
  <div class="toTop" @click="toTop" v-if="topPk">
    <i class="el-icon-arrow-up topArrow"></i>
  </div>
  <div class="fixKf" @click="showChat">
    <img src="../../assets/imgs/afterSale/kf.svg">
    <div class="kfTxt">在线联系</div>
  </div>
  <chat-window v-if="showPk" @cancel="showChange"></chat-window>
</div>
</template>

<script>
import GtHeader from "../../components/gtHeader";
import GtFooter from "../../components/gtFooter";
import Banner from "../../components/afterSale/banner";
import SelfService from "../../components/afterSale/selfService";
import Guide from "../../components/afterSale/guide";
import ServiceScope from "../../components/afterSale/serviceScope";
import {isInViewPort} from "../../mixin/mixin";
import ChatWindow from "../../components/afterSale/chatWindow";

export default {
  name: "afterSale",
  components: {ChatWindow, ServiceScope, Guide, SelfService, Banner, GtFooter, GtHeader},
  mixins:[isInViewPort],
  data(){
    return{
      topPk:false,
      servicePk:false,
      guidePk:false,
      scopePk:false,
      showPk:false
    }
  },
  methods:{
    toTop(){
      let timer = setInterval(() => {
        let scr=this.$refs.afterSale.scrollTop;
        let ispeed = Math.floor(-scr/ 5)
        this.$refs.afterSale.scrollTop=scr+ispeed;
        if (scr === 0) {
          clearInterval(timer)
        }
      }, 16)

    },
    testScroll(){
      this.eleInit()

      if(this.$refs.afterSale.scrollTop>=500){
        this.topPk=true
      }else{
        this.topPk=false

      }
    },
    scrollToTop () {

    },
    eleInit(){
      let selfService=this.isInViewPort(document.getElementById("selfService"));
      let guide=this.isInViewPort(document.getElementById("guide"));
      // let serviceScope=this.isInViewPort(document.getElementById("serviceScope"));

      if(selfService==true){
        this.servicePk=true
      }
      if(guide==true){
        this.guidePk=true
      }
      // if(serviceScope==true){
      //   this.scopePk=true
      // }

    },
    showChat(){
   this.showPk=true;
    },
    showChange(){
      this.showPk=false
    },
    selfChat(){
      this.showPk=true;

    }
  }
}
</script>

<style lang="scss" scoped>
.afterSale{
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &{
    width:10px;
  }
   &::-webkit-scrollbar{
    width:10px;
  }
  //滚动条小方块
  &::-webkit-scrollbar-thumb{
    border-radius:5px;
    background:rgb(167, 198, 156);
  }
  //滚动条轨道
  &::-webkit-scrollbar-track{
    border-radius:0px;
    height:100px;
    background:rgba(167, 198, 156,0.3);
  }
}
.toTop{
  position: fixed;
  bottom: 200px;
  right: 200px;
  width: 60px;
  height: 60px;
  background: #EAEAEA;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  .topArrow{
    cursor: pointer;
    color: white;
    font-size:40px;
  }
}
.fixKf{
  position: fixed;
  right:10px;
  top: 60%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 10;
  img{
    width: 50px;
    height: auto;
  }
  .kfTxt{
    margin-top: 10px;
    writing-mode: vertical-rl;
    line-height: 50px;
    font-size: 24px;
    opacity: 0;
    transition: 0.5s;
    color: gray;
  }
}
.fixKf:hover .kfTxt{
  opacity: 1;
  transition: 0.5s;

}


</style>
