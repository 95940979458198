<template>
<div class="selfService animated" :class="{'bounceIn':servicePk}" id="selfService" >
  <div v-if="servicePk">
  <div class="questionTop">
<!--<div class="frameTop">-->
<!--  <div class="boxEver">-->
<!--    <div class="boxIcon">-->
<!--      <img src="../../assets/imgs/afterSale/ydwt.png">-->
<!--    </div>-->
<!--    <div class="boxTxt">遇到问题？</div>-->
<!--    <div class="boxNum">1</div>-->
<!--  </div>-->
<!--  <div class="lineEver">-->
<!--    常规问题？-->
<!--  </div>-->
<!--  <div class="boxEver">-->
<!--    <div class="boxIcon">-->
<!--      <img src="../../assets/imgs/afterSale/shouhou.png">-->
<!--    </div>-->
<!--    <div class="boxTxt">自助服务</div>-->
<!--    <div class="boxNum">2</div>-->
<!--  </div>-->
<!--  <div class="lineEver">-->
<!--    解决不了？-->
<!--  </div>-->
<!--  <div class="boxEver" >-->
<!--    <div class="boxIcon">-->
<!--      <img src="../../assets/imgs/afterSale/kefu.png">-->
<!--    </div>-->
<!--    <div class="boxTxt">在线客服</div>-->
<!--    <div class="boxNum">3</div>-->
<!--  </div>-->
<!--</div>-->
<!--    <div class="saleBtns">-->
<!--      <div class="saleBtnOne">-->
<!--        自助服务-->
<!--      </div>-->
<!--      <div class="saleBtnTwo" @click="chatChange" >-->
<!--        在线客服-->
<!--      </div>-->
<!--    </div>-->
    <div class="checkList">
      <div class="checkEver" @click="checkChange('yj')" :class="{'checked':(checkPk=='yj')}">硬件问题</div>
      <div class="checkEver" @click="checkChange('rj')" :class="{'checked':(checkPk=='rj')}">软件问题</div>
      <div class="checkEver" @click="checkChange('wl')" :class="{'checked':(checkPk=='wl')}">网络问题</div>

    </div>
  </div>
  <div class="questionBom">
 <div v-for="(item,index) in nowData" :key="index" >
   <quest-ever v-if="dataPk" :quest-title="item.questTitle" :quest-main="item.questMain"></quest-ever>
 </div>
  </div>
  </div>
</div>
</template>

<script>
import QuestEver from "./questEver";
import chatWindow from "./chatWindow";
export default {
  name: "selfService",
  components: { QuestEver,chatWindow},
  props:{
    servicePk:Boolean
  },
  data(){
    return{
      chatPk:false,
     checkPk:"yj",
      nowData:[],
      dataPk:true,
      yjData:[
        {
          questTitle:"1.设备所需的外部配电要求是怎么样的？",
          questMain:"外部配电须是具有可靠接地的独立供电回路，配有2P 16A带漏电保护器的独立空气开关。"
        },
        {
          questTitle:"2.为什么需要在开机一分钟后才能才能启动系统应用？",
          questMain:"系统开机需进行内部自检，因此应在电源开关开启1分钟以后开启系统程序。"
        },
        {
          questTitle:"3.为什么无法正常开机？",
          questMain:"系统从安全考虑，开机过程中，设备输送段及称重段上应无装载货框或食材，如有将无法正常开机。"
        },
        {
          questTitle:"4.系统突然停电，设备将如何处理？",
          questMain:"如遇外部突发停电，应卸下设备上所有检测物品，关闭电源，待外部供电正常后重新开机。"
        },
      ],
      rjData:[
        {
          questTitle:"1.系统在运行过程中出现提示框，该如何解决？",
          questMain:"系统具有自我诊断功能，出现某些异常时，请注意触控平板上黄色的问题提示框，只需针对提示框问题调整后系统即可恢复正常工作。"
        },
        {
          questTitle:"2.什么情况下会影响称重精度？",
          questMain:"避免让液体渗入称重段输送皮带，可能会影响称重精度。"
        },
        {
          questTitle:"3.后台管理系统自动登录但没数据该如何解决？",
          questMain:"先退出，再进行登录验证即可！"
        },
        // {
        //   questTitle:"4.检测设备传送带不工作？",
        //   questMain:"请您先检查电源是否松动，若是没有松动，则重新启动设备，观察是否可以正常使用，若是可以正常使用则已经解决问题！若是不能使用，或者短时间内再次自动关闭，请立即联系我们客服，加以解决！"
        // },
      ],
      wlData:[
        {
          questTitle:"1.微信点餐小程序一直在转圈圈，加载中？",
          questMain:"检测wifi是否稳定，在必要时可以使用数据网络进行点餐。"
        },
        {
          questTitle:"2.设备检测超时如何解决？",
          questMain:"检查网口是否松动，如果连接的是wifi，检测wifi是否稳定。"
        },
        {
          questTitle:"3.微信小程序点餐提交订单无反应？",
          questMain:"请勿在电梯间等密闭狭小，网络不佳地域进行点餐操作，切换网络或者到网络情况良好的地区再次提交即可！"
        },
        // {
        //   questTitle:"4.检测设备传送带不工作？",
        //   questMain:"请您先检查电源是否松动，若是没有松动，则重新启动设备，观察是否可以正常使用，若是可以正常使用则已经解决问题！若是不能使用，或者短时间内再次自动关闭，请立即联系我们客服，加以解决！"
        // },
      ]
    }
  },
  methods:{
    checkChange(option){
      this.dataPk=false;

      this.checkPk=option;
      switch (option){
        case  'yj':
          this.nowData=this.yjData;
          this.$nextTick(()=>{
            this.dataPk=true;
          })
          break;
          case 'rj':
            this.nowData=this.rjData;
            this.$nextTick(()=>{
              this.dataPk=true;
            })
            break;
            case "wl":
              this.nowData=this.wlData;
              this.$nextTick(()=>{
                this.dataPk=true;
              })
              break;
      }
    },
    chatChange(){
      this.$emit("showChat",true)
    },

  },
  mounted() {
    this.nowData=this.yjData;
  }
}
</script>

<style lang="scss" scoped>
.selfService{
  position: relative;
  width: 1920px;
  background: white;

}
.questionTop{
  position: relative;
  width: 1920px;
  height: 150px;
  background: #A7C69C;
}
.questionBom{
  position: relative;
  width: 1920px;
  min-height: 350px;
  background: #A7C69C;
  padding:20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameTop{
  position: relative;
  margin: 0 auto;
  width: 1200px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 70px;
}
.boxEver{
  position: relative;
  width: 120px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .boxIcon{
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #A7C69C;
    img{
     width: 60px;
      height: 60px;
    }
  }
  .boxTxt{
    font-size: 24px;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    height: 50px;
    color: #606060;
  }
  .boxNum{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #F7A600;
    color: white;
    text-align: center;
    line-height: 30px;
    font-size: 17px;
    margin-top: 30px;
  }
}
.lineEver{
  font-size: 24px;
  color: #A7C69C;
  width: 300px;
  border-bottom: 1px solid #EEAA3D;
  text-align: center;
  line-height: 50px;
  transform: translateY(-9px);
}
.saleBtns{
  position: relative;
  width: 1200px;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 100px;
  box-sizing: border-box;
}
.saleBtnOne{
  width: 430px;
  height: 110px;
  background: #EEAA3D;
  border-radius: 55px;
  color: white;
  font-size: 32px;
  text-align: center;
  line-height: 110px;
  box-shadow: 0px 6px 6px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.saleBtnTwo{
  cursor: pointer;
  width: 430px;
  height: 110px;
  background: #A7C69C;
  border-radius: 55px;
  color: white;
  font-size: 32px;
  text-align: center;
  line-height: 110px;
  box-shadow: 0px 6px 6px 6px rgba(0, 0, 0, 0.16);

}
.checkList{
  position: relative;
  width: 1200px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 100px;
  box-sizing: border-box;
  padding-top: 50px;
}
.checkEver{
  position: relative;
  width: 130px;
  height: 45px;
  border: 1px solid #707070;
  font-size:16px ;
  box-sizing: border-box;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  color: #666666;
}
.checked{
  background: #EEAA3D;
  color: white;
  border-style: none;
}
</style>
