<template>
  <div class="saleTeam">
    <div class="frameLeft">
  完善的售后团队全天24小时咨询在线，给用户带来一对一的体验，帮助用户以更快的速度，更高的效率，更好的服务，解决用户的问题。
    </div>
    <div class="frameRight">
      <img src="../../assets/imgs/afterSale/serviceInfo.png">
    </div>
  </div>
</template>

<script>
export default {
  name: "saleTeam",
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.saleTeam {
  position: relative;
  width: 900px;
  height: 350px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frameLeft {
  position: relative;
  height: 350px;
  width: 60%;
  font-size: 18px;
  color: #666666;
}

.frameRight {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80%;
    height: auto;
  }
}

</style>
