<template>
<div class="integrated">
<div class="frameLeft">
  一体化安装为用户大大节约了安装时间以及成本，安装团队根据机械
  设备原理，可避免用户自行安装出现意外损坏机器的情况。<br>
  由专门的技术团队进行上门安装服务，为您进行区域规划在合理做到
  摆放位置合理，确保设备可以正常使用。
</div>
  <div class="frameRight">
    <img src="../../assets/imgs/afterSale/serInfo2.png">
  </div>
</div>
</template>

<script>
export default {
  name: "integrated",
  data(){
    return{

    }
  }
}
</script>

<style lang="scss" scoped>
.integrated{
  position: relative;
  width: 900px;
  height: 350px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frameLeft{
  position: relative;
  height: 350px;
  width: 60%;
  font-size: 18px;
  color: #666666;
}
.frameRight{
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 80%;
    height: auto;
  }
}

</style>
