<template>
<div class="serviceScope animated" id="serviceScope" :class="{'fadeInUp':scopePk}" >
  <div class="serScope" v-if="scopePk">
<div class="saleTitle">
  服务范围<i class="el-icon-caret-right"></i>
</div>
  <div class="frameContent">
    <div class="lineOne">
      <div class="smallCir">xx</div>
      <div class="smallCir">xx</div>

    </div>
    <div class="lineTwo">
      <div class="smallCir">xx</div>
      <div class="bigCir">杭州市</div>
      <div class="smallCir">xx</div>

    </div>
    <div class="lineThree">
      <div class="smallCir">xx</div>
      <div class="smallCir">xx</div>
    </div>
  </div>
  <div class="bomLine">
    <div class="bomEver"></div>
    <div class="bomEver"></div>

  </div>
  </div>
</div>
</template>

<script>
export default {
  name: "serviceScope",
  props:{
    scopePk:Boolean
  }
}
</script>

<style lang="scss" scoped>
.serviceScope{
  position: relative;
width: 1920px;
  height: 800px;

}
.serScope{
  position: relative;
  width: 1920px;
  height: 800px;
  background: url("../../assets/imgs/afterSale/saleScope.png");
  background-size:100% 100%;
  padding-top: 50px;
  box-sizing: border-box;
}
.saleTitle{
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  color: white;
  i{
    margin-left: 10px;
  }
}
.frameContent{
  position: relative;
width: 500px;
  height: 400px;
  margin: 0 auto;
  margin-top: 50px;

}
.smallCir{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #EEAA3D;
  color: white;
  text-align: center;
  line-height: 80px;
}
.bigCir{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #EEAA3D;
  color: white;
  text-align: center;

  line-height: 200px;
}
.lineOne{
  width: 500px;
  display: flex;
  justify-content: space-around;
  padding: 0 30px;
  box-sizing: border-box;
  margin-bottom: 30px;
}
.lineTwo{
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lineThree{
  width: 500px;
  display: flex;
  justify-content: space-around;
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 30px;
}
.bomLine{
  margin: 0 auto;
  margin-top: 100px;

  width:800px;
  height: 45px;
  display: flex;justify-content: space-between;
}
.bomEver{
  width: 220px;
  height: 45px;
  background: #EEAA3D;
  border-radius: 5px;
}

</style>
