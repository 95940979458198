<template>
<div class="banner">
<img src="../../assets/imgs/afterSale/banner.png">
</div>
</template>

<script>
export default {
  name: "banner"
}
</script>

<style lang="scss" scoped>
.banner{
  width: 1920px;
  height: 920px;
  img{
    width: 1920px;
    height: 920px;
  }
}
</style>
