<template>
<div class="chatWindow">
  <div class="chatBody">
  <div class="chatTop">
    <div class="chatTitle">客服08764为您服务</div>
    <div class="cancelIcon" @click="cancel">
      <i class="el-icon-close"></i>
    </div>
  </div>
  <div class="chatMain"  ref="chatMain">
    <div v-for="(item,index) in chatData" :key="index">
    <div class="chatYour" v-if="item.type=='your'">
      <div class="chatLine">
      <div class="yourIcon">
        <img src="../../assets/imgs/afterSale/yourIcon.png">
      </div>
      <div class="yourTxt">{{item.txt}}</div>
      </div>
    </div>
    <div class="chatMine" v-else>
      <div class="chatLine">
      <div class="mineTxt">{{item.txt}}</div>
      <div class="mineIcon">
        <img src="../../assets/imgs/afterSale/myIcon.png">

      </div>
      </div>
    </div>
    </div>
  </div>
  <div class="chatInput">
    <div class="inputMain">
      <el-input  @keyup.enter="sendFc2" v-model="inputMain" placeholder="请输入您的问题！"></el-input>
    </div>
    <div class="sendBtn" @click="sendFc">发送</div>
  </div>
  </div>
</div>
</template>

<script>
export default {
  name: "chatWindow",
  data(){
    return{
     inputMain:"",
      chatData:[
        {
          type:"your",
          txt:"您好！请问有什么可以帮到您吗？"
        }
      ],
      questionData:[
        {
          keyword:"外部配电",
          answer:"外部配电须是具有可靠接地的独立供电回路，配有2P 16A带漏电保护器的独立空气开关。"
        },
        {
          keyword:"一分钟",
          answer:"系统开机需进行内部自检，因此应在电源开关开启1分钟以后开启系统程序。"
        },
        {
          keyword:"无法正常开机",
          answer:"系统从安全考虑，开机过程中，设备输送段及称重段上应无装载货框或食材，如有将无法正常开机。"
        },
        {
          keyword:"突然停电",
          answer:"如遇外部突发停电，应卸下设备上所有检测物品，关闭电源，待外部供电正常后重新开机。"
        },
        {
          keyword:"提示框",
          answer:"系统具有自我诊断功能，出现某些异常时，请注意触控平板上黄色的问题提示框，只需针对提示框问题调整后系统即可恢复正常工作。"
        },
        {
          keyword:"称重精度",
          answer:"避免让液体渗入称重段输送皮带，可能会影响称重精度。"
        },
        {
          keyword:"没数据",
          answer:"先退出，再进行登录验证即可！"
        },
        {
          keyword:"加载中",
          answer:"检测wifi是否稳定，在必要时可以使用数据网络进行点餐。"
        },
        {
          keyword:"检测超时",
          answer:"检查网口是否松动，如果连接的是wifi，检测wifi是否稳定。"
        },
        {
          keyword:"提交订单",
          answer:"请勿在电梯间等密闭狭小，网络不佳地域进行点餐操作，切换网络或者到网络情况良好的地区再次提交即可！"
        }
      ]
    }
  },
  methods:{
    cancel(){
      this.$emit("cancel",false);
    },
    sendFc2(e){
      let key = null;
      key = e.keyCode;
      if (key === 13) {
        //
        if(this.inputMain!=""){
          let ever={
            type:"mine",
            txt:this.inputMain
          };
          this.chatData.push(ever);
          let answer="";
          this.$refs.chatMain.scrollBottom=0
          this.questionData.forEach((item)=>{
            if(this.inputMain.indexOf(item.keyword)!= -1){
              answer=item.answer;
            }
          })
          if(answer==""){
            let yourEver={
              type:"your",
              txt:"请咨询人工客服！"
            }
            this.chatData.push(yourEver);

          }else{
            let yourEver={
              type:"your",
              txt:answer
            }
            this.chatData.push(yourEver);

          }

          this.inputMain="";
        }
      }

    },
    sendFc(){
     if(this.inputMain!=""){
       let ever={
         type:"mine",
         txt:this.inputMain
       };
       this.chatData.push(ever);
       let answer="";
       this.$refs.chatMain.scrollBottom=0
       this.questionData.forEach((item)=>{
         if(this.inputMain.indexOf(item.keyword)!= -1){
           answer=item.answer;
         }
       })
       if(answer==""){
         let yourEver={
           type:"your",
           txt:"请咨询人工客服！"
         }
         this.chatData.push(yourEver);

       }else{
         let yourEver={
           type:"your",
           txt:answer
         }
         this.chatData.push(yourEver);

       }

       this.inputMain="";
     }
    },

  },
  created() {
    window.addEventListener("keydown", this.sendFc2, true);
  }
}
</script>

<style lang="scss"  scoped>
.chatWindow{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;

}
.chatBody{
  position: relative;
  width: 1200px;
  height: 800px;
  border-radius: 5px;
  overflow: hidden;
}
.chatTop{
  width: 1200px;
  height: 70px;
  background: #A7C69C;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  .chatTitle{
   height: 70px;
    line-height: 70px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .cancelIcon{
    margin-right: 20px;
    font-size: 40px;
    cursor: pointer;
  }
}
.chatMain{
  width: 1200px;
  height: 660px;
  background: #EDEDED;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.chatInput{
  width: 1200px;
  height: 70px;
  background: #EDEDED;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
   box-sizing: border-box;
  /deep/ .el-input__inner{
    width: 1000px;
    height: 45px;
  }
  .sendBtn{
    width: 140px;
    height: 45px;
    background: #EEAA3D;
    color: white;
    text-align: center;
    line-height: 45px;
    border-radius: 3px;
    font-size: 24px;
    cursor: pointer;
  }
}
.chatYour{
 width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;

  .chatLine{
   max-width: 800px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .yourIcon{
      width: 120px;
      height:120px ;
      border-radius: 50%;
      margin-right: 10px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 70%;
        height: 70%;

      }
    }
    .yourTxt{
      max-width: 600px;
      background: white;
      margin-top: 30px;
      padding: 10px;
      box-sizing: border-box;
      font-size: 20px;
      color: #999999;
      border-radius: 5px;
    }
  }
}
.chatMine{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  .chatLine{
    max-width: 800px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    .mineIcon{
      width: 120px;
      height:120px ;
      border-radius: 50%;
      margin-left: 10px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 80%;
        height: 80%;

      }
    }
    .mineTxt{
      max-width: 600px;
      background: #A7C69C;
      margin-top: 30px;
      padding: 10px;
      box-sizing: border-box;
      font-size: 20px;
      color:white;
      border-radius: 5px;

    }
  }
}
</style>
